
import Vue from 'vue'
import Home from 'icons/tabbar/home.svg?inline'
import Location from 'icons/tabbar/location.svg?inline'
import Loupe from 'icons/tabbar/loupe.svg?inline'
import Profile from 'icons/tabbar/profile.svg?inline'
import Basket from 'icons/tabbar/basket.svg?inline'
import RPHTabBarItem from 'rph/atoms/RPHTabBarItem/index.vue'

export default Vue.extend({
  name: 'RPHTabBar',
  components: {
    Home,
    Location,
    Loupe,
    Profile,
    Basket,
    RPHTabBarItem,
  },
  props: {},
  data() {
    return {
      tabs: [
        {
          url: '/',
          title: 'Главная',
          icon: Home,
          handler: false,
          isBasket: false,
        },
        {
          url: '/lekarstva-i-bady',
          title: 'Каталог',
          icon: Loupe,
          handler: false,
          isBasket: false,
        },
        {
          url: '/cart',
          title: 'Корзина',
          icon: Basket,
          handler: true,
          isBasket: true,
        },
        {
          url: '/cabinet/cabinet/profile',
          title: 'Профиль',
          icon: Profile,
          handler: true,
          isBasket: false,
        },
      ],
    }
  },
  computed: {
    logged(): boolean {
      // @ts-ignore
      return this.$auth.loggedIn
    },
  },
  methods: {
    tabHandler(handler: boolean, isBasket: boolean) {
      if (handler) {
        if (!isBasket) {
          if (this.logged) {
            this.$router.push('/cabinet/cabinet/profile')
          } else {
            this.$store.commit('signin/SET_SIGNIN_ACTIVE', true)
          }
        } else {
          if (this.$route.path !== '/cart') {
            this.$router.push('/cart')
            // @ts-ignore: Unreachable code error
            this.$gtm.push({ event: 'click_on_cart' })
          }
        }
      }
    },
  },
})
